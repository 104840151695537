.form-control,
.form-select {
  border-radius: 6px;
  padding: 0.6rem 0.8rem;
  font-size: 1.4rem;
  color: $color-grey-dark-1;
}

.form-control[type="file"] {
  padding: 0.4rem 0.8rem;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus,
.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus,{
  box-shadow: 0 0 0 0.35rem rgba(var(--bs-danger-rgb),.25);
}

.form-control:focus,
.form-select:focus {
  border: 1px solid $color-primary-tint;
  box-shadow: 0 0 0 0.35rem $color-primary-transparent;
}

.form-label {
  font-size: 1.2rem;
  margin-bottom: 0.6rem;
}

.form-check {
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.form-check-input {
  cursor: pointer;
}

.form-check-label {
  font-size: 1.4rem;
  margin-top: 0.4rem;
  cursor: pointer;
}

.invalid-feedback {
  font-size: 1.4rem;
}