@import '../../sass/variables';


.icon {
    svg {
        width: 1.8rem;
        height: 1.8rem;
    }
}

.btn-sm {
    .icon {
        svg {
            width: 1.6rem;
            height: 1.6rem;
        }
    }
}

.icon-sm {
    svg {
        width: 1.6rem;
        height: 1.6rem;
    }
}