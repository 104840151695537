.main-header {
  display: flex;
  justify-content: space-between;
}

.divider {
  border-top: 1px solid $color-grey-light-3;
}

// NAV PILLS

.nav.nav-pills {
  gap: 1.2rem;

  .nav-link {
    padding: 0.8rem 1.4rem;
    color: $color-grey-dark-1;;
    font-size: 1.4rem;
    font-weight: 500;
    cursor: pointer;

    &:hover,
    &:active {
      background-color: $color-grey-light-2 !important;
    }

    &.active {
      background-color: $color-primary !important;
      color: white;
    }

    &.active:hover {
      background-color: $color-primary-shade !important;
      color: white;
    }
  }
}