@import '../../../sass/variables';


.payment-summary {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2.4rem;
    row-gap: 0.8rem;

    text-align: right;

    .payment-summary-label {
        margin-bottom: 0;
    }

    .payment-total-income {
        color: $color-green;
        font-weight: 500;
    }
    .payment-total-expenses {
        color: $color-red;
        font-weight: 500;
    }
    .payment-balance {
        font-weight: 500;
        font-size: 1.8rem;
    }
    .payment-balance.profit {
        color: $color-green;
    }
    .payment-balance.loss {
        color: $color-red;
    }

}