@import '../../sass/variables';

.nav-menu {
    height: 100%;
    //overflow-x: hidden;
    //overflow-y: scroll;

    background-color: $color-grey-light-1;
    border-right: 1px solid $color-grey-light-border;
    padding: 2.4rem;

    &::-webkit-scrollbar {
        width: 0.9rem;
    }
    &::-webkit-scrollbar-thumb {
        background-color: $color-grey-light-scroll;
    }

    .menu {
        min-height: 100%;
        list-style-type: none;
        padding-left: 0;

        .menu-item {
            margin-bottom: 0.4rem;
        }

        .menu-item-link:link,
        .menu-item-link:visited {
            display: flex;
            align-items: center;
            gap: 1.2rem;

            font-size: 1.4rem;
            color: inherit;
            padding: 1rem 1.4rem;
            text-decoration: none;

            border-radius: 6px;
            transition: all 0.2s;
        }

        .menu-item-link:hover,
        .menu-item-link:active,
        .menu-item-link.active {
            background-color: $color-grey-light-2;
        }
    }

}

