h3, .h3 {
  font-size: 2.8rem;
  margin-bottom: 1.6rem;
}

h4, .h4 {
  font-size: 2.4rem;
  margin-bottom: 1.2rem;
}

h5, .h5 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

h6, .h6 {
  font-size: 1.6rem;
  margin-bottom: 0.8rem;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  line-height: 1.5;
}

p {
  font-size: 1.4rem;
  margin-bottom: 1.6rem;
}

.lead {
  font-size: 1.8rem;
  font-weight: 400;
}

.sub-heading {
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  color: $color-grey-light-4;
  margin-bottom: 0.6rem;
}

.text-muted {
  color: $color-grey-light-3 !important;
}

.text-color-gradient {
  color: $color-primary;
  background: linear-gradient(to right, $color-primary, $color-primary-shade);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-medium {
  font-size: 1.3rem;
}

.text-small {
  font-size: 1.2rem;
}

.text-bold {
  font-weight: 500;
}

.text-right {
  text-align: right !important;
}

.line-height-medium {
  line-height: 1.3;
}

.unordered-list li {
  list-style-type: disc;
  margin-bottom: 0.2rem;
}

.link-primary {
  &:link,
  &:visited {
    color: $color-primary !important;
    transition: all 0.2s;
    text-decoration: none;
    border-bottom: 1px solid transparent;
  }
  &:hover,
  &:active {
    text-decoration: none;
    border-bottom: 1px solid $color-primary;
  }
}

.grid {
  display: grid;
  column-gap: 2.4rem;

  .span-full {
    grid-column: 1 / -1;
  }
}

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
}

.grid--3-cols {
  grid-template-columns: repeat(3, 1fr);
}

.hidden {
  display: none;
}



