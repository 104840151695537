@import '../../sass/variables';

.btn {
    padding: 0.8rem 1.2rem;
    border-radius: 6px;
    font-size: 1.4rem;
    font-weight: 500;
    border: none !important;

    > .icon.mr-1 {
        margin-right: 0.4rem !important;
    }
}

.btn-primary {
    background-color: $color-primary !important;
    border-color: $color-primary !important;

    &:hover,
    &:active {
        background-color: $color-primary-shade !important;
        border-color: $color-primary-shade !important;
    }

    &:focus {
        box-shadow: 0 0 0 0.35rem $color-primary-transparent;
    }
}

.btn-md {
    padding: 0.6rem 0.8rem;
}

.btn-sm {
    font-size: 1.4rem;
    font-weight: 400;
    padding: 0.2rem 0.4rem;
}

.btn-light {
    background-color: transparent;
    border-color: transparent;
    border: 1px solid transparent;
    color: inherit;

    &:hover,
    &:active {
        background-color: $color-grey-light-2 !important;
        border-color: $color-grey-light-2 !important;
    }
}

.btn-link {
    color: $color-primary !important;
    text-decoration: none;
    border-bottom: 1px solid transparent;

    &:hover,
    &:active {
        color: $color-primary !important;
    }

    > .btn-label {
        transition: all 0.2s;
        border-bottom: 1px solid transparent;

        &:hover {
            border-bottom: 1px solid $color-primary;
        }
    }
}

.btn-tiny {
    height: 23px;
    padding: 0.4rem;
    line-height: 0.8;
    margin-top: -4px;
}

.btn-transparent {
    background: none;
    border: none;
}

.btn-icon {
    display: inline-flex;
    align-items: center;
    gap: 0.4rem;
}

.btn-round {
    border-radius: 100px;
}