$color-primary-tint: #339af0;
$color-primary: #1c7ed6;
$color-primary-shade: #1864ab;
$color-primary-transparent: rgba(28,126,214,0.3);

$color-grey-light-1: #fafafa;
$color-grey-light-2: rgba(189,197,209,.2);
$color-grey-light-2-5: #e5e5e5;
$color-grey-light-3: #adb5bd;
$color-grey-light-4: #8c98a4;
$color-grey-dark-1: #333333;
$color-grey-dark-2: #666666;

$color-grey-light-border: rgba(231, 234, 243, 0.7);
$color-grey-light-scroll: rgba(189,197,209,.6);

$color-red: #dc3545;
$color-green: #28a745;