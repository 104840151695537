@import '../../sass/variables';

.breadcrumb {
    margin-bottom: 1.2rem;

    .breadcrumb-item {
        font-size: 1.4rem;

        &.active {
            color: $color-grey-light-4;
        }
    }
}

