/*

--- 01 TYPOGRAPHY SYSTEM

- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

--- 07 WHITESPACE

- SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: auto !important;
}

body {
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  line-height: 1.1;
  font-size: 1.6rem;
  font-weight: 400;
  color: $color-grey-dark-1;
}


.app-container {
  display: grid;
  grid-template-columns: 28rem 1fr;
  grid-template-rows: 6.4rem 1fr;

  min-height: 100vh;
}

.main {
  padding: 2.4rem;
  max-width: 120rem;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
}

