@import '../../sass/variables';

.modal.modal-lg {
  .modal-dialog {
    max-width: 85rem !important;
  }
}

.modal {
  .modal-content {
    border-radius: 6px;
    .alert {
      margin: 1.8rem 1.8rem 0 1.8rem !important;
    }
    .modal-header {
      padding: 1.6rem 2rem;
      border-bottom: 1px solid $color-grey-light-border;

      .modal-title {
        font-size: 2rem !important;
      }
    }
    .modal-body {
      padding: 1.8rem 2rem;
    }
    .modal-footer {
      padding: 1.6rem 2rem;
      border-top: 1px solid $color-grey-light-border;
    }

  }
}

.modal.show {
  display: block;
}

.modal-dialog-scrollable {
  form {
    overflow-y: auto;
  }
}
