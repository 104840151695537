@import '../../../sass/variables';

.payment-list-item {
    display: grid;
    grid-template-columns: 2% 8% 15% 1fr 20% 5% 10%;
    column-gap: 2.4rem;
    align-items: center;

    font-size: 1.4rem;
    border-radius: 6px;
    padding: 1rem;
    margin-bottom: 0.2rem;

    .amount {
        font-weight: 500;
    }
}

.payment-list-item.pending {
    opacity: 0.7;
}

.payment-list-item.expense {
    background-color: rgb(253, 237, 236);

    .amount {
        color: $color-red;
    }
}

.payment-list-item.income {
    background-color: rgba(25, 135, 84, 0.09);


    .amount {
        color: $color-green;
    }
}
