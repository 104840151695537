@media print {
  html {
    font-size: 50%;
  }
  button, a {
    display: none !important;
  }
  .app-container {
    display: block;
  }
  .header,
  .nav-menu {
    display: none !important;
  }

  .list {
    .list-item {
      border: none !important;
      box-shadow: none !important;
      padding: 0 !important;
    }
  }
  .test-case-table {
    .case-more-row.hidden {
      display: table-row !important;
    }
  }
}