@import '../../sass/variables';

.list {
  padding: 0;
  list-style-type: none;

  .list-item {
    padding: 2rem;
    border: 1px solid $color-grey-light-border;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(140,152,164,.1);
    margin-bottom: 2.4rem;
  }

}

.list-clickable {

  .list-item {
    transition: all 0.2s;
  }
  .list-item:hover,
  .list-item:active {
    border: 1px solid $color-grey-light-2-5;
    box-shadow: 0 6px 12px rgba(140,152,164,.2);
  }
}