@import '../../../sass/variables';

.payment-details {
    background-color: $color-grey-light-1;
    border-radius: 6px;
    margin-bottom: 0.8rem;
    padding: 1.8rem 1.4rem 2.4rem 1.4rem;

    font-size: 1.4rem;

    .payment-details-actions {
        display: flex;
        justify-content: flex-end;
        gap: 1.2rem;
        padding-bottom: 2.4rem;
    }

    .payment-details-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2.6rem;
        row-gap: 1.6rem;
    }

    .payment-details-label {
      font-weight: 500;
    }

}

.payment-details.expense {
    .amount {
        color: $color-red;
    }
}

.payment-details.income {
    .amount {
        color: $color-green;
    }
}