@import '../../sass/variables';

.navbar {
    height: 100%;
    font-size: 1.6rem;

    display: flex;
    align-items: center;
    gap: 2.4rem;

    background-color: white !important;
    border-bottom: 1px solid $color-grey-light-border;
    padding: 1.6rem 2.4rem;

    .navbar-nav {
        gap: 1.2rem;
        flex-direction: row !important;

        &:first-child {
            margin-right: auto;
        }

        .nav-link {
            font-size: 1.4rem;
        }

        .nav-item {
            .dropdown-menu {
                border: 1px solid $color-grey-light-border !important;

                .dropdown-divider {
                    border-top: 1px solid $color-grey-light-border !important;
                }
            }
        }

        .dropdown-item {
            font-size: 1.4rem;
            padding: 0.8rem 1.2rem;
        }

        .dropdown-toggle::after {
            margin-left: 0.6rem;
        }

    }


}

