@import '../../sass/variables';

.logo-box {
    background-color: $color-grey-light-1;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 3.2rem;
    border-right: 1px solid $color-grey-light-border;
}

.logo {
    &:link,
    &:visited {
        font-size: 2.4rem;
        font-weight: 700;
        transition: all 0.2s;
    }
}