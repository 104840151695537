@import '../../../sass/variables';

.payment-pagination {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    .payment-month-year {
        margin-bottom: 0;
    }
}