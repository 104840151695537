@import '../../sass/variables';


.table-container {
  border: 1px solid $color-grey-light-border;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(140,152,164,.1);
  overflow: hidden;
  padding: 0.8rem 0;
}

.table {
  line-height: 1.5;
  margin-bottom: 0;

  thead {
    th {
      border-bottom: 1px solid $color-grey-light-border;
      font-size: 1.2rem;
      font-weight: 500;
      text-transform: uppercase;
      color: $color-grey-light-4;
      padding: 1.2rem 0.8rem;
    }
  }
  tbody {
    td, th {
      font-size: 1.4rem;
      color: inherit;
      padding: 0.6rem 0.6rem;
      border-bottom: 0;
      vertical-align: middle;
    }
  }
}

.table-container {
  .table {
    thead {
      th {
        &:first-child {
          padding-left: 1.6rem;
        }
        &:last-child {
          padding-right: 1.6rem !important;
        }
      }
    }
    tbody {
      td, th {
        &:first-child {
          padding-left: 1.6rem;
        }
        &:last-child {
          padding-right: 1.6rem !important;
        }
      }
    }
  }
}

.table-small {
  thead {
    th {
      padding: 0.4rem;
      border-bottom: none;
    }
  }
  tbody {
    th, td {
      padding: 0.4rem;
    }
  }
}

.table-clickable-rows {
  tbody {
    tr {
      td, th {
        transition: all 0.2s;
      }
    }
    tr:hover,
    tr:active {
      td, th {
        background-color: $color-grey-light-2;
      }
    }
  }
}

.table-striped {
  tr:nth-of-type(odd) {
    td {
      background-color: white;
      --bs-table-bg-type: none;
    }
  }
  tr:nth-of-type(even) {
    td {
      background-color: $color-grey-light-1;
      --bs-table-bg-type: none;
    }
  }
}
