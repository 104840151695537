.mb-1 {
  margin-bottom: 0.6rem;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mb-3 {
  margin-bottom: 1.6rem !important;;
}

.mb-4 {
  margin-bottom: 2.4rem !important;
}

.mt-1 {
  margin-top: 0.6rem;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mt-3 {
  margin-top: 1.6rem !important;
}

.mt-4 {
  margin-top: 2.4rem !important;
}

.mr-1 {
  margin-right: 0.6rem !important;
}

.mr-2 {
  margin-right: 1rem !important;
}

.mr-3 {
  margin-right: 1.6rem !important;
}

.mr-4 {
  margin-right: 2.4rem !important;
}

.space-between {
  display: flex;
  justify-content: space-between;
}